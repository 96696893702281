
<template>
  <header
    class="header"
    :style="{position:fixed?'fixed':'absolute'}"
  >
    <div class="logo">
      <router-link :to="{path:'/'}">
        <img
          v-if="white"
          src="@/assets/logo-white.png"
        >
        <img
          v-else
          src="@/assets/logo-black.png"
        >
      </router-link>
    </div>
    <div
      v-show="true"
      class="menu"
      :class="{'black':!white,'hide':!showMenu}"
    >
      <span
        class="menu-svg"
        @click="toLoading"
      >

        <img
          v-show="white"
          src="@/assets/menu-white.svg"
        >
        <img
          v-show="!white"
          src="@/assets/menu-black.svg"
        >
      </span>
      <!-- <span
        v-for="(item,index) in menuArr "
        :key="index"
        class="menu-item"
        :class="{'active':swiperActiveIndex===index}"
        @click="handleSwiperTo(index)"
      >{{ item }}</span> -->

    </div>
    <div
      v-if="this.$route.hash === '#home'"
      class="loading"
      :class="{show:loading}"
    ></div>
  </header>
</template>
<script>
export default {
  props: {
    swiperActiveIndex: Number,
    white: Boolean,
    showMenu: Boolean,
    fixed: {
      type: Boolean,
      defalt: true
    }
  },
  data() {
    return {
      menuArr: ['掌玩网络', '业务介绍', '企业荣誉', '企业招聘', '人才培养', '发展历程', '合作伙伴'],
      loading: false
    }
  },
  methods: {
    handleSwiperTo(index) {
      console.log('handleSwiperTo:', index)
      this.$emit('swiper-to', index)
    },
    toLoading() {
      this.loading = true
      setTimeout(() => {
        this.$router.push('/qiye#home')
      }, 250)
    }
  }
}
</script>
<style lang="scss" scoped>
@keyframes menuHover {
  0% {
    width: 0%;
  }

  50% {
    width: 70%;
  }

  to {
    width: 100%;
  }
}
header {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  z-index: 10;
  padding-top: 20px;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 5%;
  padding-right: 5%;
  .logo {
    width: 193px;
    height: 55px;
  }
  .menu {
    color: #fdfdfd;
    transition: all 0.3s;
    opacity: 1;
    &.hide {
      transform: translateY(-200px);
      opacity: 0;
    }
    &.black {
      color: #333333;
      .menu-item {
        color: #333333;
        &::after {
          background: #333333;
        }
      }
    }
    .menu-item {
      padding: 0 10px;
      align-items: center;
      color: #fdfdfd;
      cursor: pointer;
      text-decoration: none;
      position: relative;
      &::after {
        content: ' ';
        background: #fdfdfd;
        height: 2px;
        width: 0;
        position: absolute;
        left: 50%;
        bottom: -10px;
        transition: all 0.2s ease-in-out;
        opacity: 0;
      }
      &:hover::after {
        left: 0%;
        width: 100%;
        opacity: 0.9;
      }
      &.active {
        color: #ed2908;
        &::after {
          bottom: -11px;
          height: 3px;
          left: 0%;
          opacity: 0.9;
          width: 100%;
          background: #ed2908;
        }
      }
    }
  }
}
.menu-svg {
  display: block;
  width: 30px;
  cursor: pointer;
}
@keyframes load {
  0% {
  }
}
.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  top: 0;
  left: 0;
  clip-path: circle(0% at 100% 0);
  transition: all 0.25s;
  &.show {
    opacity: 1;
    clip-path: circle(150% at 100% 0);
  }
}
</style>