<template>
  <div>
    <div
      class="img"
      :style="'background-image: url('+img+')'"
      @click="showPriview"
    ><span>「 {{content}} 」</span></div>
    <p class="content">
      {{title}}
    </p>
  </div>
</template>
<script>
export default {
  props: ['img', 'content', 'title'],
  methods: {
    showPriview() {
      const url = this.img.split('.')[0] + '-1.' + this.img.split('.')[1]
      console.log('showPriview', url)
      // const a= ""
      // a.replace('.jpg')
      this.$emit('preview', url)
    }
  }
}
</script>
